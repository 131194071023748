import { Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../I4C_Logo_with_Text.png';

export default function CommonBanner({ primary, secondary }) {
  return (
    <div style={{ backgroundColor: '#F6FAFA' }}>
      <Container maxWidth='md' sx={{ padding: '25px 16px 32px 16px' }}>
        <Link to={`/login`}>
          <img src={logo} alt='I4C Logo' width='145px' style={{ marginBottom: '1rem', marginTop: '1rem' }} />
        </Link>
        <Typography fontWeight={700} fontSize={{ xs: 34, md: 44 }} color='secondary'>
          {primary}
        </Typography>
        <Typography fontWeight={700} mt={1} fontSize={{ xs: 28, md: 34 }} color='secondary'>
          {secondary}
        </Typography>
      </Container>
    </div>
  );
}
