import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import onError from '../error/OnError';
import { donorDetailsContext } from '../providers/DonorDetailsProvider';
import apiService from '../services/ApiService';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../I4C_Logo_with_Text.png';

export default function Login() {
  const [, setDonorDetails] = useContext(donorDetailsContext);
  const [inputEmail, setInputEmail] = useState('');
  const [unknown, setUnknown] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  document.title = 'Donor Login';

  const login = async (email) => {
    setLoading(true);
    const donorData = await apiService.getDonorData(email).catch(onError);
    setDonorDetails(donorData);
    if (donorData) {
      history.push('/' + email + '/impact-statement');
    } else {
      setLoading(false);
      setUnknown(true);
    }
  };

  return (
    <div>
      <div>
        <Container maxWidth='md' sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <Link to={`/login`}>
            <img src={logo} alt='I4C Logo' width='150px' />
          </Link>
        </Container>
      </div>
      <div style={{ backgroundColor: '#F4F9F9' }}>
        <Container maxWidth='md' sx={{ padding: '40px 16px 40px 16px' }}>
          <Typography fontWeight={700} fontSize={{ xs: 34, md: 44 }} color='secondary'>
            Donor Login
          </Typography>
        </Container>
      </div>
      <Container maxWidth='md' sx={{ marginTop: { xs: 4, md: 7 } }}>
        <Grid item xs={8}>
          <TextField
            required
            id='outlined-basic'
            style={{ maxWidth: '25rem', minWidth: '20rem', borderRadius: '40px' }}
            label='Email Address'
            variant='outlined'
            InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
            error={unknown}
            helperText={unknown ? 'Unknown Username' : undefined}
            color='secondary'
            onChange={(event) => {
              setInputEmail(event.target.value.toLowerCase());
              setUnknown(false);
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <Button
            disabled={inputEmail.length <= 0}
            variant='contained'
            sx={{ color: '#fff', backgroundColor: '#008d87', textTransform: 'none', borderRadius: '20px', width: '84px', height: '40px', marginTop: '2rem' }}
            onClick={() => login(inputEmail)}
          >
            <Typography variant='Roboto'>Login</Typography>
          </Button>
        </Grid>
        <Grid item xs={8} mt={2}>
          {loading ? <CircularProgress color='info' /> : <></>}
        </Grid>
      </Container>
    </div>
  );
}
