import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function ImpactCard({ variantTitle, variantNumber, content, amount, cause = undefined, isCenter = false }) {
  const textAlign = isCenter ? 'center' : null;
  return (
    <Card
      elevation={0}
      sx={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        flexDirection: 'row',
        padding: '0.5rem',
        borderBottom: '1px solid rgba(128, 128, 128, 0.137)',
      }}
    >
      <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'underline' }}>
        <img src={`${process.env.PUBLIC_URL}/images/cause_detail/${cause}/icon.png`} alt='Cause_image' title={cause} />
      </CardContent>
      <div>
        <div sx={{ padding: '0' }}>
          <Typography variant='Roboto' fontSize={17} color='text.primary' sx={{ textDecoration: 'underline' }}>
            {content}
          </Typography>
        </div>
        <div sx={{ padding: '0' }}>
          {cause ? (
            <Typography variant='Roboto' fontSize={12}>
              {cause}
            </Typography>
          ) : null}
        </div>
      </div>
      <CardContent sx={{ marginLeft: 'auto' }}>
        <Typography variant={variantNumber} color='text.primary'>
          <b>${amount ? amount : 0}</b>
        </Typography>
      </CardContent>
    </Card>
  );
}
