import React, { useContext } from 'react';
import '../App.css';
import { Container, Grid, Typography } from '@mui/material';
import { donorDetailsContext } from '../providers/DonorDetailsProvider';

export default function Footer() {
  const [donorData, setDonorDetails] = useContext(donorDetailsContext);
  return (
    <div style={{ backgroundColor: '#F6FAFA' }}>
      <Container maxWidth='md' sx={{ padding: '25px 16px 32px 16px', marginTop: '2rem' }}>
        <Grid mt={4} mb={4}>
          <Typography variant='Roboto' fontSize={18} sx={{ whiteSpace: 'pre-wrap' }}>
            {donorData && donorData.front_page.endtxt}
          </Typography>
        </Grid>
      </Container>
    </div>
  );
}
