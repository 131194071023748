import React, { useState } from 'react';
import ImpactCard from './ImpactCard';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import { Typography } from '@mui/material';

export default function DistributionOverview({ timeTag, causes, user, scrollToDistributions }) {
  const [page, setPage] = useState(1);
  const handleChange = (event, currentPage) => {
    setPage(currentPage);
    // Takes user back to top of distributions component on each page click
    scrollToDistributions();
  };

  // i.e 1 means each page has one date header and under it all the distributions on that date (only effects the past tab)
  const elementsPerPage = 1;
  // Upcoming distributions objects have no associated date attribute so cannot be grouped, thus they will all be grouped under an undefined key
  // Therefore below the headers are only rendered if the user is on the 'past' tab.
  let groupByDate = causes ? Object.groupBy(causes[timeTag.toLowerCase()], (cause) => cause.date) : null;
  // The upcoming tab is rendered all at once so only need 1 page
  let pagesCount = groupByDate == null ? 0 : timeTag == 'Past' ? Math.ceil(Object.keys(groupByDate).length / elementsPerPage) : 1;

  return (
    <Grid container rowSpacing={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {groupByDate &&
        Object.entries(groupByDate)
          .filter((item, index) => {
            // This handles the pagination, it only passes through a slice of the array using indexes based on what page the user is on and how many array elements you want displayed per page
            const startIdx = (page - 1) * elementsPerPage;
            return index >= startIdx && index < startIdx + elementsPerPage;
          })
          .map(([date, items]) => {
            return (
              <Grid container sx={{ flexDirection: 'column' }}>
                {timeTag == 'Past' && (
                  <div className='distributions-date-header'>
                    <img style={{ marginRight: '10px' }} src={`${process.env.PUBLIC_URL}/images/misc/calendar_month.png`} />
                    <Typography>
                      <b>{date}</b>
                    </Typography>
                  </div>
                )}
                <div>
                  {items.map((cause) => (
                    <Grid key={`${cause.charity}${cause.date}`} item md={12} xs={12}>
                      <Link
                        to={`/${user}/distribution-detail/${timeTag.toLowerCase()}/${cause.id}`}
                        title={`Learn more about how your money is helping ${cause.charity}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <ImpactCard variantTitle='Roboto' variantNumber='h6' content={`${cause.charity}`} amount={cause.amount} cause={cause.cause} />
                      </Link>
                    </Grid>
                  ))}
                </div>
              </Grid>
            );
          })}
      <Pagination
        count={pagesCount}
        page={page}
        sx={{
          '& .Mui-selected': { backgroundColor: '#F6FAFA', borderBottom: '1px solid #008D87', borderRadius: '0px' },
          '& .MuiPagination-ul': { marginTop: '1rem' },
        }}
        shape='rounded'
        onChange={handleChange}
      />
    </Grid>
  );
}
