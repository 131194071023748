import * as React from 'react';
import { useHistory } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';

export default function CauseList({ user, cause, handleOnChange }) {
  const history = useHistory();
  return (
    <ListItem
      disablePadding
      onClick={() => {
        history.push(`/${user}/causes-detail/${cause}`);
      }}
      title={`Learn more information about ${cause}`}
      sx={{ mb: 1 }}
    >
      <ListItemIcon
        sx={{
          mr: 1,
          minWidth: '30px',
        }}
      >
        <img src={`${process.env.PUBLIC_URL}/images/cause_detail/${cause}/icon.png`} alt='Cause_image' title={cause} />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant='Roboto' sx={{ textDecoration: 'underline' }}>
            {cause}
          </Typography>
        }
      />
    </ListItem>
  );
}
