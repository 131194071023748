import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function handleClick(event) {
  event.preventDefault();
}

export default function Breadcrumb({ user, text, isOverview }) {
  const history = useHistory();
  return (
    <div role='presentation' onClick={handleClick}>
      <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNextIcon style={{ margin: -5 }} />}>
        <Link
          underline='hover'
          variant='Roboto'
          color='text.primary'
          href='/getting-started/installation/'
          onClick={() => {
            history.push(`/${user}/impact-statement`);
          }}
        >
          Dashboard
        </Link>
        {isOverview ? null : (
          <Typography variant='Roboto' sx={{ display: 'flex', alignItems: 'center', textDecoration: 'underline' }} color='text.primary'>
            {text}
          </Typography>
        )}
      </Breadcrumbs>
    </div>
  );
}
