import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function CauseSection({ children, number, des, area }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'left' }}>
      <Typography variant='h6' color='text.primary' mb={3} gutterBottom sx={{ fontWeight: 'bold' }}>
        {area}
      </Typography>
      <div style={{ marginBottom: '3%' }}>{children}</div>
      <Typography mb={1} variant='h4' gutterBottom>
        {number}
      </Typography>
      <Typography mb={6} variant='h7' sx={{ width: '90%' }}>
        {des}
      </Typography>
    </Box>
  );
}
