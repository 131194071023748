import React, { useContext } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { donorDetailsContext } from '../providers/DonorDetailsProvider';
import CauseList from './CauseList';
import { Grid } from '@mui/material';

export default function CauseSupported({ user, causes }) {
  const [donorData, setDonorDetails] = useContext(donorDetailsContext);
  return (
    <Grid container className='data-container'>
      <Card elevation={0} sx={{ padding: '1rem' }}>
        <Typography variant='h6' color='text.primary' gutterBottom>
          <b>{donorData.front_page.causessofartxt}</b>
        </Typography>
        <List sx={{ cursor: 'pointer' }}>
          {causes && [...new Set(causes.map((cause) => cause.cause))].map((cause) => <CauseList key={cause} cause={cause} user={user} />)}
        </List>
      </Card>
    </Grid>
  );
}
