import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

export default function DistributionModule({ children, text }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'top', padding: '1rem', justifyContent: 'space-between' }}>
      <Grid mb={3} sx={{ maxWidth: { xs: '100%', md: '40%' } }}>
        <Typography variant='h7'>{text}</Typography>
      </Grid>
      <div>{children}</div>
    </Box>
  );
}
