import { Switch, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import './App.css';
import Home from './pages/Home';
import Login from './pages/Login';
import ImpactStatement from './pages/ImpactStatement';
import DistributionDetail from './pages/DistributionDetail';
import CauseDetail from './pages/CauseDetail';
import DonorDetailsProvider from './providers/DonorDetailsProvider';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFAFA',
    },
    secondary: {
      main: '#008d87',
    },
    brand: {
      main: '#1b224c',
    },
  },
  typography: {
    h3: {
      fontFamily: 'Merriweather',
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'Merriweather',
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'Merriweather',
      fontWeight: 600,
    },
    h6: {
      fontFamily: 'Merriweather',
      fontWeight: 600,
    },
    body1: {
      fontFamily: 'Merriweather',
      fontWeight: 400,
    },
    fontFamily: 'Merriweather',
    Roboto: {
      fontFamily: 'Roboto',
      fontWeight: 400,
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <DonorDetailsProvider>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route exact path='/login'>
            <Login />
          </Route>
          <Route path='/:user/impact-statement'>
            <ImpactStatement />
          </Route>
          <Route path='/:user/distribution-detail/:timetag/:id'>
            <DistributionDetail />
          </Route>
          <Route path='/:user/causes-detail/:cause'>
            <CauseDetail />
          </Route>
        </DonorDetailsProvider>
      </Switch>
    </ThemeProvider>
  );
}

export default App;
