import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { donorDetailsContext } from '../providers/DonorDetailsProvider';
import onError from '../error/OnError';
import apiService from '../services/ApiService';

function ImpactDataInfoBar({ scrollToDistributions }) {
  const [donorData, setDonorDetails] = useContext(donorDetailsContext);
  const { user } = useParams();

  useEffect(() => {
    if (!donorData)
      Promise.resolve(apiService.getDonorData(user))
        .then((donorData) => setDonorDetails(donorData))
        .catch(onError);
  }, [user, donorData, setDonorDetails]);


  return (
    <Grid container elevation={0} className='data-container'>
      <Grid item xs={12} md={3} className='data-box' elevation={0}>
        <CardContent>
          <Typography gutterBottom className='data-box-text'>
            {donorData.front_page.currentbaltxt}
          </Typography>
          <Typography variant='h4'>${donorData.balance}</Typography>
        </CardContent>
      </Grid>
      <Grid item xs={12} md={3} className='data-box' elevation={0}>
        <CardContent>
          <Typography gutterBottom className='data-box-text'>
            {donorData.front_page.pastdistrotxt}
          </Typography>
          <Typography variant='h4'>${donorData.past_distributions_total}</Typography>
        </CardContent>
        <CardActions style={{ padding: '18px' }}>
          <Typography
            onClick={scrollToDistributions}
            title='See each of your past distributions'
            fontSize={12}
            variant='Roboto'
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            See more
          </Typography>
        </CardActions>
      </Grid>
      <Grid item xs={12} md={3} className='data-box' elevation={0}>
        <CardContent>
          <Typography gutterBottom>{donorData.front_page.upcomingdisttxt}</Typography>
          <Typography variant='h4'>{donorData.upcoming_distribution_rate}</Typography>
        </CardContent>
        <CardActions style={{ padding: '18px' }}>
          <Typography fontSize={12} variant='Roboto'>
            Minimum annual rate: 5%
          </Typography>
        </CardActions>
      </Grid>
      <Grid item xs={12} md={3} elevation={0}>
        <CardContent>
          <Typography gutterBottom>Next distribution amount</Typography>
          <Typography variant='h4'>${donorData.upcoming_distribution_amount}</Typography>
        </CardContent>
      </Grid>
    </Grid>
  );
}

export default ImpactDataInfoBar;
