import React, { useState, useEffect, useContext, useCallback } from 'react';
import './DistributionDetail.css';
import { useParams } from 'react-router';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Breadcrumb from '../components/Breadcrumb';
import CircularProgress from '@mui/material/CircularProgress';
import CardContent from '@mui/material/CardContent';
import AggregateDistributions from '../components/AggregateDistributions';

import onError from '../error/OnError';
import { donorDetailsContext } from '../providers/DonorDetailsProvider';
import apiService from '../services/ApiService';
import DetailSection from '../components/DetailSection';
import DistributionModule from '../components/DistributionModule';
import CommonBanner from '../components/CommonBanner';
import Footer from '../components/Footer';

export default function DistributionDetail() {
  const [donorData, setDonorDetails] = useContext(donorDetailsContext);
  const [data, setData] = useState({ outputs: [] });
  const [loading, setLoading] = useState(false);
  const { user, timetag, id } = useParams();
  const isUpcoming = useCallback(() => timetag === 'upcoming', [timetag]);

  document.title = 'Distribution Details';

  useEffect(() => {
    function assignData(donorData) {
      const detail = isUpcoming() ? donorData.upcoming_distributions[id] : donorData.past_distributions[id];
      const outputs = Array(3)
        .fill(1)
        .map((x, y) => x + y) // digits 1 to 3
        .filter((i) => detail[`distoutput${i}`] && detail[`outputtype${i}`])
        .map((i) => ({
          number: detail[`distoutput${i}`],
          des: detail[`outputtype${i}`],
        }));
      const aggregations =
        detail.aggregatetext1 && detail.aggregatetext2 && detail.aggregatedistro
          ? { text1: detail.aggregatetext1, text2: detail.aggregatetext2, total: detail.aggregatedistro }
          : undefined;
      const info = {
        charity: detail.charity,
        charityId: detail.charityId,
        charityCode: detail.charityCode,
        date: detail.date,
        amount: detail.amount,
        outputs,
        aggregations,
        pretext: detail.pretext,
        posttext: detail.posttext,
        endnote1: detail.endnote1,
        endnote2: detail.endnote2,
        relevancetext: detail.relevancetext,
        operationareatext: detail.operationareatext,
      };
      setData(info);
    }

    if (!donorData) {
      setLoading(true);
      Promise.resolve(apiService.getDonorData(user))
        .then((donorData) => {
          setDonorDetails(donorData);
          setLoading(false);
          assignData(donorData);
        })
        .catch(onError);
    } else {
      assignData(donorData);
    }
  }, [id, user, donorData, setDonorDetails, isUpcoming]);

  return (
    <>
      {data && <CommonBanner primary={data.charity} secondary={data.date} />}
      <Container maxWidth='md' sx={{ mt: 2.5 }}>
        {loading ? (
          <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress color='info' size={200} thickness={1} />
          </Container>
        ) : (
          <Grid container>
            <Grid item md={12} xs={12}>
              <Breadcrumb user={user} text={isUpcoming() ? 'Upcoming Distribution' : 'Past Distribution'} />
            </Grid>

            {/* Top data bar */}
            <Grid mt={3} mb={3} container elevation={0} className='data-container'>
              <Grid item xs={12} md={3} className='data-box' elevation={0} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CardContent>
                  {data.charityId && (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/${data.charityId}/Charity_Logo ${data.charityCode}.png`}
                      alt='Charity_Logo'
                      style={{ height: '100%', maxWidth: '120px', marginRight: '1rem' }}
                    />
                  )}
                </CardContent>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                className={data.aggregations && 'data-box'}
                elevation={0}
                sx={
                  data.aggregations
                    ? { minWidth: '17rem' }
                    : {
                        minWidth: '17rem',
                        marginLeft: { xs: '0rem', md: '2rem' },
                        marginTop: { xs: '1rem', md: '0rem' },
                        marginBottom: { xs: '-2rem', md: '0rem' },
                      }
                }
              >
                <CardContent>
                  <Typography variant='Roboto' className='data-box-text'>
                    {isUpcoming() ? `Based on your preferences, your upcoming distribution to this charity will be` : `Your past distribution was:`}
                  </Typography>
                  <Typography sx={{ marginTop: { md: 3, xs: 2 } }} variant='h4'>
                    ${data.amount}
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item xs={12} md={3} elevation={0} sx={{ minWidth: '17rem' }}>
                <CardContent>
                  <AggregateDistributions timetag={timetag} year={data.date?.split(' ')[2]} charity={data.charity} aggregatedDistros={data.aggregations} />
                </CardContent>
              </Grid>
            </Grid>

            {/* Distributions provided bar */}
            <Grid
              container
              mb={3}
              elevation={0}
              className='data-container'
              sx={{ flexWrap: 'nowrap', padding: { md: '2rem', xs: '1rem' }, flexDirection: 'column' }}
            >
              <Typography variant='h6' mb={3} mt={1}>
                <b>{data.pretext}</b>
              </Typography>
              <Grid
                mb={data.endnote1 && { md: 3 }}
                sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '1rem', justifyContent: 'flex-start' }}
              >
                {data.outputs &&
                  data.outputs.map((item, index) => (
                    <Grid item key={index} className='distributions-supported-container'>
                      <DetailSection number={item.number} index={index} des={item.des}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/${data.charityId}/Impact ${data.charityCode}${index + 1}.jpg`}
                          alt='Charity_image'
                          className='distributions-image'
                          width='100%'
                          height='100%'
                          style={{ maxWidth: '300px' }}
                        />
                      </DetailSection>
                    </Grid>
                  ))}
              </Grid>
              <Typography variant='Roboto' fontSize={11}>
                {data.endnote1 && data.endnote1}
                <br />
                {data.endnote2 && data.endnote2}
              </Typography>
            </Grid>

            {/* Relevance to Beneficiaries and Programs */}
            {data.relevancetext ? (
              <Grid mb={3} md={12} xs={12} className='data-container'>
                <DistributionModule text={data.relevancetext}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/${data.charityId}/Relevance ${data.charityCode}.png`}
                    alt='Relevance_image'
                    width='100%'
                    height='100%'
                    style={{ maxWidth: '600px' }}
                  />
                </DistributionModule>
              </Grid>
            ) : (
              <></>
            )}

            {/* About */}
            {data.operationareatext ? (
              <Grid mb={2} md={12} xs={12} className='data-container'>
                <DistributionModule text={data.operationareatext}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/${data.charityId}/Area_Of_Operation ${data.charityCode}.jpg`}
                    alt='Area_Of_Operation_image'
                    width='100%'
                    height='100%'
                    style={{ maxWidth: '600px' }}
                  />
                </DistributionModule>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        )}
      </Container>
      {data && <Footer />}
    </>
  );
}
