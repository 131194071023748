import React, { useState, useEffect, useContext } from 'react';
import './CauseDetail.css';
import { useParams } from 'react-router';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Breadcrumb from '../components/Breadcrumb';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import onError from '../error/OnError';
import { donorDetailsContext } from '../providers/DonorDetailsProvider';
import apiService from '../services/ApiService';
import CauseSection from '../components/CauseSection';
import CommonBanner from '../components/CommonBanner';
import Footer from '../components/Footer';

export default function CauseDetail() {
  const [donorData, setDonorDetails] = useContext(donorDetailsContext);
  const [data, setData] = useState({ causes: [] });
  const [loading, setLoading] = useState(false);
  const { user, cause } = useParams();

  document.title = 'Cause Detail';

  useEffect(() => {
    function assignFrom(donorData) {
      const givenCause = donorData.cause_detail.filter((x) => x.causename === cause)[0];
      const dataFromApi = { causes: [] };
      dataFromApi.intro = givenCause.causeintro;
      dataFromApi.causes = Array(4)
        .fill(1)
        .map((x, y) => x + y) // digits 1 to 4
        .filter((i) => givenCause[`causefactarea${i}`] && givenCause[`causefactnumber${i}`] && givenCause[`causefacttxt${i}`])
        .map((i) => ({
          area: givenCause[`causefactarea${i}`],
          number: givenCause[`causefactnumber${i}`],
          text: givenCause[`causefacttxt${i}`],
        }));
      setData(dataFromApi);
    }

    if (!donorData) {
      setLoading(true);
      Promise.resolve(apiService.getDonorData(user))
        .then((donorData) => {
          setDonorDetails(donorData);
          setLoading(false);
          assignFrom(donorData);
        })
        .catch(onError);
    } else {
      assignFrom(donorData);
    }
  }, [cause, donorData, setDonorDetails, user]);

  return (
    <>
      {cause && <CommonBanner primary={cause} />}
      <Container maxWidth='md' sx={{ mt: 2.5 }}>
        {loading ? (
          <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress color='info' size={200} thickness={1} />
          </Container>
        ) : (
          <Grid container rowSpacing={3}>
            <Grid item md={12} xs={12}>
              <Breadcrumb user={user} text='Cause Detail' />
            </Grid>
            {data.intro ? (
              <Grid
                item
                md={12}
                xs={12}
                mb={3}
                mt={4}
                sx={{ display: 'flex', justifyContent: 'left', boxShadow: '0px 0px 10px 1px rgba(209,209,209,0.4)', padding: '1rem', borderRadius: '10px' }}
              >
                <Typography variant='Roboto' gutterBottom sx={{ lineHeight: '18px', fontSize: '16px' }}>
                  {data.intro}
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
            <Box
              mb={3}
              sx={{
                boxShadow: '0px 0px 10px 1px rgba(209,209,209,0.4)',
                padding: 4,
                borderRadius: '10px',
              }}
            >
              <Grid container rowSpacing={1} mb={-8} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {data.causes &&
                  data.causes.map((data, index) => (
                    <Grid item className='cause-container' mb={6} md={6} xs={12} key={index}>
                      <CauseSection number={data.number} des={data.text} pictureName={`picture${index + 1}`} area={data.area}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/cause_detail/${cause}/picture${index + 1}.svg`}
                          alt='Cause_image'
                          width='120px'
                          height='120px'
                        />
                      </CauseSection>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Grid>
        )}
      </Container>
      {cause && <Footer />}
    </>
  );
}
