import React, { useEffect, useContext } from 'react';
import './ImpactStatement.css';
import { useParams } from 'react-router';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CauseSupported from '../components/CausesSupported';
import ImpactDataInfoBar from '../components/ImpactDataInfoBar';

import onError from '../error/OnError';
import { donorDetailsContext } from '../providers/DonorDetailsProvider';
import apiService from '../services/ApiService';
import CircularProgress from '@mui/material/CircularProgress';
import DistributionCard from '../components/DistributionCard';
import Footer from '../components/Footer';
import CommonBanner from '../components/CommonBanner';

export default function ImpactStatement() {
  const [donorData, setDonorDetails] = useContext(donorDetailsContext);
  const { user } = useParams();

  document.title = 'Impact Statement';

  useEffect(() => {
    if (!donorData)
      Promise.resolve(apiService.getDonorData(user))
        .then((donorData) => setDonorDetails(donorData))
        .catch(onError);
  }, [user, donorData, setDonorDetails]);

  const causes = {
    past: donorData?.past_distributions ?? null,
    upcoming: donorData?.upcoming_distributions ?? null,
  };

  // Takes user back to top of distributions component on each page click
  function scrollToDistributions() {
    const mainRoot = document.getElementById('scrollTo');
    mainRoot.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <>
      {donorData && <CommonBanner primary={donorData.front_page.frontpagetitle} secondary={donorData.name} />}
      <Container maxWidth='md' sx={{ mt: 5 }}>
        {!donorData ? (
          <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress color='info' size={200} thickness={1} />
          </Container>
        ) : (
          <Grid container rowSpacing={3}>
            <div item xs={12} md={12} className='account-preference-info'>
              <img
                style={{ marginRight: '1rem', marginLeft: '20px' }}
                src={`${process.env.PUBLIC_URL}/images/misc/contact_support.png`}
                alt='Contact support icon'
              />
              <Typography variant='Roboto' fontSize={12}>
                To change your distribution preferences or top-up your existing account email: info@i4c.org.au
              </Typography>
            </div>
            <Grid item xs={12} md={12}>
              <ImpactDataInfoBar scrollToDistributions={scrollToDistributions} />
            </Grid>
            <Grid item xs={12} md={12}>
              <CauseSupported causes={donorData.past_distributions} user={user} />
            </Grid>
            <Grid item id='scrollTo' xs={12} md={12}>
              <DistributionCard scrollToDistributions={scrollToDistributions} causes={causes} user={user} />
            </Grid>
          </Grid>
        )}
      </Container>
      {donorData && <Footer />}
    </>
  );
}
