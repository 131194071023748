import { createContext, useState } from 'react';

export const donorDetailsContext = createContext();

const DonorDetailsProvider = (props) => {
    // this state will be shared with all components 
    const [donorDetails, setDonorDetails] = useState();

    return (
        // this is the provider providing state
        <donorDetailsContext.Provider value={[donorDetails, setDonorDetails]}>
            {props.children}
        </donorDetailsContext.Provider>
    );
};

export default DonorDetailsProvider;