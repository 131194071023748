import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './DetailSection.css';

export default function DetailSection({ children, number, des, index }) {
  return (
    <Box>
      <Box>
        <div>{children}</div>
      </Box>

      <Box>
        <Typography mb={1} mt={1} variant='h4' gutterBottom sx={{ textAlign: 'left' }}>
          {number}
        </Typography>
        <Typography variant='h7' sx={{ mb: 1.5 }}>
          {des}
        </Typography>
      </Box>
    </Box>
  );
}
