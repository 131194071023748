import React from 'react';
import Typography from '@mui/material/Typography';

export default function AggregateDistributions({ timetag, year, charity, aggregatedDistros }) {
  return aggregatedDistros ? (
    <>
      <Typography variant='Roboto' gutterBottom>
        {aggregatedDistros && aggregatedDistros.text1}
      </Typography>
      <Typography variant='h4' sx={{ mb: 2, mt: 2 }}>
        {aggregatedDistros.total}
      </Typography>
      <Typography variant='Roboto'>{aggregatedDistros.text2}</Typography>
    </>
  ) : (
    <></>
  );
}
