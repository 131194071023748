class ApiService {
  async getDonorData(email) {
    const url = `${process.env.REACT_APP_API_END_POINT}?email=${email}`
    const response = await fetch(url)
    return await response.json()
  }
}

const apiService = new ApiService()

export default apiService