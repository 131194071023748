import React from 'react';
import { useHistory } from "react-router-dom";
import atlassianFoundationLogo from '../AtlassianFoundation.png';
import Button from '@mui/material/Button';

export default function Home() {
    const history = useHistory()
    const loginPage = () => history.push("/login")

    return (
        <div className="App">
            <header className="App-header">
                <div className="dark-overlay">
                    <div style={{display: "flex", flexDirection: "column", width: "80%", height: "100%", margin: "auto", alignItems: "center", justifyContent: "center"}}>
                        <img src={atlassianFoundationLogo} className="App-logo" alt="logo"/>
                        <Button color="brand" variant="contained" sx={{color: "#fff", mt: 5}} onClick={loginPage}>Login</Button>
                    </div>
                </div>
            </header>
        </div>
    )
}
