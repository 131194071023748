import React, { useState, useContext } from 'react';
import './DistributionCard.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { donorDetailsContext } from '../providers/DonorDetailsProvider';
import DistributionOverview from './DistributionOverview';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DistributionCard({ causes, user, scrollToDistributions }) {
  const [value, setValue] = useState(0);
  const [donorData, setDonorDetails] = useContext(donorDetailsContext);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className='distributions-container'>
      <Typography variant='h6' color='text.primary' mb={2}>
        <b>{donorData && donorData.front_page.distributionstitle}</b>
      </Typography>
      <Box sx={{ borderColor: 'divider' }}>
        <Tabs
          value={value}
          TabIndicatorProps={{ style: { backgroundColor: '#008D87' } }}
          variant='fullWidth'
          onChange={handleChange}
          aria-label='distribution tabs'
          sx={{
            '.Mui-selected': {
              backgroundColor: `#F6FAFA`,
            },
          }}
        >
          <Tab
            label={
              <Typography variant='Roboto' fontSize={18} color='text.primary'>
                Past
              </Typography>
            }
            sx={{ textTransform: 'none' }}
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Typography variant='Roboto' fontSize={18} color='text.primary'>
                Upcoming
              </Typography>
            }
            sx={{ textTransform: 'none' }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <DistributionOverview scrollToDistributions={scrollToDistributions} causes={causes} timeTag='Past' user={user} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DistributionOverview scrollToDistributions={scrollToDistributions} causes={causes} timeTag='Upcoming' user={user} />
      </TabPanel>
    </Box>
  );
}
